
export const columnsConfig = (content) => {
  const renderHeader = function (h, { column, $index }) {
    return [
      h(
        'el-checkbox', {
          props: {
            value: content.checkAll,
            indeterminate: content.isIndeterminate
          },
          on: {
            change: content.updateAllSelected // 选中事件
          }
        }
      ),
      column.label
    ]
  }
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '文件材料题名',
      prop: 'documentName',
      width: '200px'
    },
    {
      label: '落实情况',
      prop: 'implementSituation',
      'render-header': renderHeader,
      width: '120px'
    },
    {
      label: '文件类型',
      prop: 'fileType',
      width: '120px',
      attrs: {
        options: content.options,
        selectedField: ['value', 'label'],
        placeholder: '请选择'
      }
    },
    {
      label: '原件份数',
      prop: 'originalCopies',
      width: '120px'
    },
    {
      label: '复印件份数',
      prop: 'copyCopies',
      width: '120px'
    },
    {
      label: '备注',
      prop: 'memo',
      width: '200px'
    },
    {
      label: '文件',
      prop: 'fileList',
      HiddenOverflow: true
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
