<template>
  <div>
    <Basetable
      :columns="columnsConfig"
      :showPage="false"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :type="type"
      ref="tableData"
      :webPage="false"
      :selfChangeHeight="200"
      :isCaculateHeight="false"
      class="main-page-table"
      v-if="tableData&&tableData.length>0"
    >
      <template slot="fileTypeHeader">
        <el-menu
          :default-active="'1'"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-submenu index="1">
            <template slot="title">文件类型</template>
            <el-menu-item index="1-1" @click="selctAll('S')">线上资料</el-menu-item>
            <el-menu-item index="1-2" @click="selctAll('X')">线下资料</el-menu-item>
          </el-submenu>
        </el-menu>
      </template>
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- <IconButton
          @click.prevent="openPreview(scope.row)"
          content="预览"
          icon="iconfont iconyulan"
          v-if="scope.row.fileId !== '0'"
        ></IconButton> -->
        <el-tooltip
          v-if="label==='客户资料' && scope.row.isSign!=='0'"
          class="item"
          effect="dark"
          content="上传"
          placement="top"
        >
          <base-button
            type="text"
            label=""
            icon="iconfont iconshangchuan"
            btnType="upload"
             accept=".png,.jpg,.bmp,.jpeg,.doc,.docx,.xlsx,.xls,.pdf"
            :http-request="
              function (url) {
                return Upload(url, scope.row, scope.$index);
              }
            "
            style="margin: 0px 10px"
          ></base-button>
        </el-tooltip>
        <!-- <IconButton
          @click.prevent="deleteTemplate(scope.row, 1, scope.$index)"
          content="删除"
          icon="iconfont iconshanchu1"
          v-if="scope.row.fileId !== '0'"
        ></IconButton> -->
        <!-- isSign判断是否可以编辑 -->
        <icon-button
          @click="appendFile(scope.row, 2, scope.$index)"
          content="追加文件"
          icon="iconfont iconzhuijia"
          v-if="scope.row.isSign==='0'"
        />
      </template>
      <template slot="implementSituation" slot-scope="scope">
        <el-checkbox
          v-model="scope.row.implementSituation"
          :true-label="1"
          :false-label="0"
          @change="checkboxchange(scope.row.implementSituation, scope.$index)"
        ></el-checkbox>
      </template>
      <!-- 表头 -->
      <template slot="fileType" slot-scope="scope">
        <el-select v-model="scope.row.fileType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template slot="originalCopies" slot-scope="scope">
        <el-input v-model="scope.row.originalCopies" maxlength="5"></el-input>
      </template>
      <template slot="copyCopies" slot-scope="scope">
        <el-input v-model="scope.row.copyCopies" maxlength="5"></el-input>
      </template>
      <template slot="memo" slot-scope="scope">
        <el-input
          v-model="scope.row.memo"
          placeholder="请输入内容"
          maxlength="200"
          type="memo"
        ></el-input>
      </template>
      <template slot="fileList" slot-scope="scope">
        <!-- 文件列按钮组 -->
        <file-button-group v-for="(item ,index) in scope.row.fileListDetailVOS" :key="index"
          :item="item"
          :index="index"
          :scope="scope"
          :fileIndex='fileIndex'
          :isSign='item.isSign'
          @previewShow="openPreview"
          @Upload="Upload"
          :suppliersSealType='suppliersSealType'
          @deleteTemplate="deleteTemplate"
        />
      </template>
    </Basetable>
    <!-- <pre-view-dialog :visible.sync="visible" :fileData="fileData" @dowload='dowload' /> -->
      <pre-view :fileId="fileData.currentFileId" :isOpen='true' :fileType="fileData.currentFileFileSuffix" :count="count" />
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
// import NumberInput from '@/components/input/number-input.vue'
// import PreViewDialog from './pre-view-dialog.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import Basetable from '@/components/common/table/base-table/base-table.vue'
import FileButtonGroup from './file-button-group.vue'
import { columnsConfig } from '../utils/contentMain.config.js'
import { implementApi, fileListApi } from '@/api/businessApi'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import BaseButton from '@/components/common/button/base-button/base-button.vue'

export default {
  components: { Basetable, IconButton, FileButtonGroup, PreView, BaseButton },
  props: {
    suppliersSealType: String, // 盖章类型
    tableData: Array,
    arr: Array,
    fileIndex: Number, // 下标
    label: String
  },
  data () {
    return {
      count: 0,
      value: '',
      visible: false,
      fileData: {},
      checkAll: false,
      checkedCitiesL: [],
      isIndeterminate: false,
      type: '',
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      options: [
        {
          value: '1',
          label: '线上资料'
        },
        {
          value: '2',
          label: '线下资料'
        }
      ]
    }
  },
  watch: {
    tableData () {
      this.getChecked()
    }
  },
  computed: {
    columnsConfig () {
      return columnsConfig(this)
    },
    api () {
      return implementApi
    }
  },
  methods: {
    dowload (data) {
      const fileId = data.currentFileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 文件类型选择
    selctAll (type) {
      const newarr = this.tableData
      newarr.forEach((item, index) => {
        this.$set(newarr[index], 'fileType', type === 'S' ? '1' : '2')
      })
      this.$emit('update:tableData', newarr)
    },
    // 验证是否为正整数
    validNumber (r, v, c) {
      if (v) {
        const reg = /^[1-9]\d{0,4}$/
        if (!reg.test(v)) {
          c(new Error('请输入正确的份数'))
        } else {
          c()
        }
      } else {
        c()
      }
    },
    // 重新上传
    async Upload (param, row, index) {
      this.$parent.saveInfo('upload')
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      const arr = param.file.name.split('.')
      const suffix = (arr[arr.length - 1]).toLowerCase()
      const lite = [
        'doc',
        'docx',
        'xlsx',
        'xls',
        'pdf',
        'png',
        'jpg',
        'jpeg',
        'bmp'
      ]
      const flag = lite.some((val) => {
        return suffix === val
      })
      if (!flag) {
        this.warning('请上传doc,docx,xlsx,xls,pdf,png,jpg,jpeg,bmp格式文件')
        return
      }
      const res = await this.UploadFile(param)
      console.log(row, 'row')
      const data = {
        businessId: row.businessId,
        documentId: row.documentId,
        fileId: res.keyId,
        templateId: row.keyId,
        fileName: res.fileName,
        keyId: row.keyIdF
      }
      fileListApi.UploadElementFile([data]).then((res) => {
        if (res.data) {
          this.success('文件上传成功')
          this.$emit('getFilelist')
        }
      })
      // fileListApi.UploadLmplementFile(data).then((data) => {
      //   if (data.data) {
      //     this.tableData[index].fileId = res.keyId
      //     this.tableData[index].suffix = suffix
      //     this.success('文件上传成功')
      //   }
      // })
    },
    async UploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.file(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    },
    // 删除文件
    deleteTemplate (row, type, index, i) {
      this.$confirm(type === 1 ? '是否删除当前项?' : '是否删除文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 构造参数
          // type:1 删除当前行合同文件项 2 删除已上传的合同文件
          const data = {
            businessId: row.businessId || this.$route.query.businessId,
            documentId: row.documentId,
            keyId: row.keyId,
            keyIdF: row.keyIdF,
            documentType: row.documentType
          }
          // 追加文件时删除没有保存数据
          if (row.addFile) {
            this.tableData[index].fileListDetailVOS.splice(i, 1)
            return
          }
          this.deleteFile(data, index, type, i)
        })
        .catch(() => {})
    },
    // 删除文件
    deleteFile (data, index, type, i) {
      fileListApi.deleteFileElement([data.keyIdF]).then(res => {
        if (res.data) {
          this.success('删除成功')
          this.tableData[index].fileListDetailVOS.splice(i, 1)
        } else {
          this.error('删除失败')
        }
      })
    },
    // 追加文件
    appendFile (row, type, index) {
      console.log(row, 'row')
      // if (row.isSign === '1') {
      //   return this.warning('当前文件不可追加')
      // }
      const [data] = row.fileListDetailVOS
        ? row.fileListDetailVOS.filter((item) => item.addFile)
        : [null]
      if (data) {
        return this.warning('请先上传文件')
      }
      if (!row.fileListDetailVOS) {
        this.$set(row, 'fileListDetailVOS', [])
      }
      row.fileListDetailVOS.push({ fileId: '0', fileName: '', addFile: true })
    },
    // 重新上传文件
    updataFile (file) {
      const files = file.target.files[0]
      const formData = new FormData()
      formData.append('file', files)
      this.api
        .UploadLmplementFile(formData)
        .then((res) => {
          this.arr.forEach((item) => {
            if (res.data.fileName === item.documentName) {
              item.fileId = res.data
            }
          })
          this.$message.success('文件上传成功')
        })
        .catch((res) => {
          this.$message.error('文件上传失败')
        })
    },
    // 回显选中状态
    getChecked () {
      this.checkedCitiesL = []
      if (this.tableData.length > 0) {
        this.tableData.forEach((item) => {
          if (item.implementSituation === 1) {
            this.checkedCitiesL.push(item)
          }
        })
        const checkedCount = this.checkedCitiesL.length
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.tableData.length
        this.checkAll = checkedCount === this.tableData.length
      }
    },
    // 表格行内多选框点击
    checkboxchange (val, index) {
      if (val) {
        this.checkedCitiesL.push(this.tableData[index])
      } else {
        this.checkedCitiesL.pop()
      }
      const checkedCount = this.checkedCitiesL.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tableData.length
      this.checkAll = checkedCount === this.tableData.length
    },
    // 全选
    updateAllSelected (val) {
      this.checkAll = !this.checkAll
      this.checkedCitiesL = val
        ? JSON.parse(JSON.stringify(this.tableData))
        : []
      const newarr = this.tableData
      newarr.forEach((item, index) => {
        this.$set(newarr[index], 'implementSituation', val ? 1 : 0)
      })
      this.$emit('update:tableData', newarr)
      this.getChecked()
    },
    // 预览
    openPreview (data) {
      // this.visible = true
      this.fileData = data
      this.count++
    }
  },
  mounted () {},
  created () {}
}
</script>
<style lang="scss" scoped>
/deep/ .el-menu{
  background: transparent;
  display: flex;
  justify-content: center;
}
/deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
  border: 0 !important;
}
/deep/ .el-menu.el-menu--horizontal{
  border: 0 !important;
}
/deep/ .el-submenu__title{
height: 23px !important;
line-height: 23px !important;
// background-color:#4A7CF0 !important;
border: 0 !important;
color: #757380 !important;

}
/deep/ .el-form-item__error {
  z-index: 99999;
}
/deep/ .el-table .cell {
  overflow: visible !important;
}
/deep/ .el-form-item {
  padding: 4px 0 !important;
  margin-bottom: 0px !important ;
}
/deep/.el-table th > .cell {
  .el-checkbox {
    margin-right: 5px;
  }
}

/deep/ .el-checkbox__inner:hover {
  border-color: #D9E5EE;
}
/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #D9E5EE !important;
}
// /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
// .el-checkbox__input.is-indeterminate .el-checkbox__inner {
//   background-color: #dcb580 !important;
//   border-color: #D9E5EE !important;
// }
// /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
//   background-color: #dcb580 !important;
//   border-color: #D9E5EE !important;
// }
/deep/ .el-input__inner {
  border-color: #D9E5EE;
}
.left {
  width: 80%;
}
.row {
  display: flex;
}
.cont-rl {
  justify-content: space-between;
}
</style>
